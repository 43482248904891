.maincontainer {
  padding: 50px;
  overflow-y: auto;
  background: #f5f5f5;
  height: 100vh;
  width: 100vw;
}

.topbar {
  display: flex;
  justify-content: flex-start;
}

.headingholder {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 30px 0px;
}
.inputfeesitem {
  width: 100%;
  height: 60px;
  padding-left: 20px;
  background-image: linear-gradient(to bottom, #f05e1363, #fff);
  border: 1px solid #f05e13;
  border-radius: 10px;
  font-weight: 900;
  font-size: 24px;
  margin: 0px;
}
.addstudenticon {
  width: 40px;
  height: auto;
}

.heading {
  color: #f05e13;
  font-weight: 900;
  font-size: 18px;
}

.centerformholder {
  height: fit-content;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  padding: 1vh 25vw;
}

.resetformholder {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 5px;
  border: 1px solid black;
  border-radius: 10px;
  padding: 1vw;
  margin: 1vh 25vw;
}
.feesblock {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  align-items: self-start;
  gap: 5px;
  margin-top: 20px;
}
.individualfeesinputblock {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  align-items: self-start;
  gap: 2px;
}
.inputblock {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
}
.feesinputblock {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}
.formlabel {
  font-size: 18px;
  color: #314a72;
  font-weight: 600;
}
.feeslabel {
  font-size: 15px;
  color: #314a72;
  font-weight: 500;
}
.discountlabel{
  font-size: 22px;
  color: #314a72;
  font-weight: 600;
}
.inputtext {
  width: 100%;
  height: 60px;
  padding-left: 20px;
  background-image: linear-gradient(to bottom, #f05e1363, #fff);
  border: 1px solid #f05e13;
  border-radius: 10px;
  font-weight: 900;
  font-size: 24px;
  margin: 0px;
}
.feesinputtext {
  width: 100%;
  height: 60px;
  padding-left: 20px;
  background-image: linear-gradient(to bottom, #f05e1363, #fff);
  border: 1px solid #f05e13;
  border-radius: 10px;
  font-weight: 900;
  font-size: 24px;
  margin: 0px;
}
.selectblock {
  width: 100%;
  height: 60px;
  padding: 5px 30px;
  background-image: linear-gradient(to bottom, #f05e1363, #fff);
  border: 1px solid #f05e13;
  border-radius: 10px;
  font-weight: 900;
  font-size: 24px;
  color: black;
}

.savebuttonholder {
  padding: 20px 0px;
}

.savebutton {
  color: white;
  background: #f05e13;
  height: fit-content;
  padding: 5px 60px;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 800;
}

.savebutton:hover,
.backbutton:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.errormessage {
  color: #ff0000;
  font-size: 12px;
  padding: 0px 0px 10px 0px;
  margin: 0px;
}

.fileinput {
  content: "Select some files";
  display: inline-block;
  background: linear-gradient(to top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
}

.schoollogo {
  width: 4vw;
}

.actionicons {
  width: 2vw;
  height: fit-content;
}

.actionicons:hover {
  cursor: pointer;
}
.feesitemblock {
  width: 100%;
  display: flex;
  gap: 5px;
  justify-content: space-between;
}
.addbutton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: none;
  border-radius: 10px;
  background: #29ac14;
  color: white;
  padding: 5px 20px;
  font-size: 16px;
  font-weight: 8 00;
  cursor: pointer;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .maincontainer {
    padding: 5vw;
  }
  .feesitemblock {
    flex-direction: column;
  }
  .inputfeesitem {
    width: 100%;
  }
  .centerformholder {
    padding: 0;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

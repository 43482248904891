.maincontainer {
  height: 80vh;
  width: 50vw;
  border-radius: 10px;
  background: white;
  padding: 20px;
  overflow-y: scroll;
  z-index: 999 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
}

.topbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.heading {
  color: #29ac14;
  font-weight: 900;
  font-size: 22px;
}

.formholder {
  padding: 20px 0px;
}

.inputblock {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
}

.formlabel {
  font-size: 18px;
  color: #314a72;
  font-weight: 600;
}

.inputtext {
  width: 100%;
  height: 60px;
  padding: 30px;
  background-image: linear-gradient(to bottom, whitesmoke 10%, #fff);
  border: 1px solid grey;
  border-radius: 10px;
  font-weight: 900;
  font-size: 24px;
}

.inputtextarea {
  width: 100%;
  height: fit-content;
  padding: 30px;
  background-image: linear-gradient(to bottom, whitesmoke 10%, #fff);
  border: 1px solid grey;
  border-radius: 10px;
  font-weight: 900;
  font-size: 18px;
  resize: none;
}

.selectblock {
  width: 100%;
  height: 60px;
  padding: 5px 30px;
  background-image: linear-gradient(to bottom, whitesmoke, #fff);
  border: 1px solid grey;
  border-radius: 10px;
  font-weight: 900;
  font-size: 24px;
  color: black;
}

.errormessage {
  color: #ff0000;
  font-size: 12px;
}

.submitbuttonholder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.submitbutton {
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: none;
  border-radius: 10px;
  background: #29ac14;
  color: white;
  padding: 5px 20px;
  font-size: 16px;
  font-weight: 8 00;
}

.submitbutton:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.fileinput {
  content: "Select some files";
  display: inline-block;
  background: linear-gradient(to top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
}

.classchekholder {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.selectallholder {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.otherholders {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 3vw;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .maincontainer {
    width: 80vw;
  }

  .otherholders {
    flex-wrap: wrap;
    row-gap: 0;
    gap: 5vw;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

.maincontainer {
  padding: 50px;
  overflow-y: auto;
  background: #f5f5f5;
  height: 100vh;
  width: 100vw;
}

.topbar {
  display: flex;
  justify-content: flex-start;
}

.heading {
  color: #29ac14;
  font-weight: 900;
}

.headingholder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0px;
}

.lefthead {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.noticeboardicon {
  width: 40px;
  height: auto;
}

.addbutton {
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: none;
  border-radius: 10px;
  background: #29ac14;
  color: white;
  padding: 5px 20px;
  font-size: 16px;
  font-weight: 8 00;
}

.middleholder {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 50px;
}

.leftside {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  overflow-y: auto;
  gap: 20px;
}

.topicnameholder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border: 1px solid #29ac14;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
}

.rightside {
  flex: 1.5;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
  background-image: linear-gradient(to bottom, #29ac14a6 65%, #fff);
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
}

.topichead {
  font-size: 30px;
  color: #314a72;
  font-weight: 900;
}

.noticedescription {
  font-size: 20px;
  color: #314a72;
  font-weight: 600;
  padding: 0px 20px;
}

.backbutton:hover,
.addbutton:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.topicnameholder:hover {
  cursor: pointer;
}

.topicimage {
  width: 30%;
  height: auto;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .maincontainer {
    padding: 5vw;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

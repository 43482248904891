.maincontainer {
  overflow-y: auto;
  background: #f5f5f5;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.loginheading {
  color: #f05e13;
  font-weight: 900;
  font-size: 40px;
}

.formholder {
  padding: 10px 0px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3vh;
  flex-direction: column;
}

.formlabel {
  font-size: 18px;
  color: #314a72;
  font-weight: 600;
}

.forminput {
  font-size: 14px;
  background: transparent;
  border: 1px solid grey;
  border-radius: 5px;
  height: 30px;
  padding: 10px;
  width: 100%;
}

.inputholder {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  flex-direction: column;
  width: 20vw;
}

.loginbutton {
  color: white;
  background: #f05e13;
  height: fit-content;
  padding: 5px 60px;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 800;
}

.errormessage {
  color: #ff0000;
  font-size: 12px;
  margin: 1vh 0 0 0;
}

.loginbutton:hover {
  cursor: pointer;
  transform: scale(1.04);
}

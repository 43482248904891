.maincontainer {
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  background: #6fa8df;
  padding: 5vh 10vw;
  overflow-y: hidden;
}

.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5vh;
}

.logo {
  width: 4vw;
}

.schoolcode {
  color: white;
  padding: 0;
  margin: 0;
  margin-top: -1vh;
  font-size: 1.2vw;
}

.leftbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
}

.detailholder {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
}

.dashheading {
  color: #fff;
  font-size: 35px;
  margin: 0px;
  padding: 0px;
}

.schoolname {
  color: #fff;
  font-size: 20px;
  margin: 0px;
  padding: 0px;
}

.rightbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 40px;
}

.middlecardsholder {
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 5vh;
  row-gap: 10vh;
  padding-top: 20px;
}

.librarycard {
  width: 24vw;
  height: 24vh;
  gap: 22px;
  border-radius: 10px;
  background-image: "../../assets/icons/libraryicon.png";
  background-repeat: repeat;
  background-color: #feedbf;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.2);
}

.messagecard {
  width: 24vw;
  height: 24vh;
  gap: 22px;
  border-radius: 10px;
  background-image: "../../assets/icons/libraryicon.png";
  background-repeat: repeat;
  background-color: #e4eaff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.2);
}

.icon {
  width: 4vw;
}

.noticeboardcard {
  width: 24vw;
  height: 24vh;
  gap: 22px;
  border-radius: 10px;
  background-image: "../../assets/icons/libraryicon.png";
  background-repeat: repeat;
  background-color: #e7ffe3;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.2);
}

.addstudentcard {
  width: 24vw;
  height: 24vh;
  gap: 22px;
  border-radius: 10px;
  background-image: "../../assets/icons/libraryicon.png";
  background-repeat: repeat;
  background-color: #ffefe7;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.2);
}

.bottomcardtext {
  color: #374f74;
  font-size: 18px;
}

.librarycard:hover,
.messagecard:hover,
.noticeboardcard:hover,
.addstudentcard:hover {
  transform: scale(1.04);
  cursor: pointer;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .maincontainer {
    padding: 5vh 5vw;
    overflow-y: scroll;
  }

  .leftbar {
    align-items: center;
  }

  .topbar {
    width: 100%;
  }

  .logo {
    width: 10vw;
  }

  .dashheading {
    font-size: 5vw;
  }

  .schoolname {
    font-size: 5vw;
  }

  .leftbar {
    gap: 2vw;
  }

  .rightbar {
    gap: 5vw;
  }

  .icons {
    width: 7vw;
  }

  .middlecardsholder {
    display: grid;
    grid-template-columns: auto;
    row-gap: 5vh;
    padding-top: 20px;
  }

  .icon {
    width: 10vw;
  }

  .librarycard {
    width: 100%;
    justify-content: center;
  }

  .addstudentcard {
    width: 100%;
    justify-content: center;
  }

  .messagecard {
    width: 100%;
    justify-content: center;
  }

  .noticeboardcard {
    width: 100%;
    justify-content: center;
  }

  .bottomcardtext {
    font-size: 6vw;
    margin: 0px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

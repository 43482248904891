.maincontainer {
  padding: 50px;
  overflow-y: auto;
  background: #f5f5f5;
  height: 100vh;
  width: 100vw;
}

.topbar {
  display: flex;
  justify-content: flex-start;
}

.headingholder {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 30px 0px;
}

.addstudenticon {
  width: 40px;
  height: auto;
}

.heading {
  color: #f05e13;
  font-weight: 900;
  font-size: 18px;
}

.activeholder {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.active,
.deactive {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
}

.selectholder {
  width: 60%;
  height: 60px;
  padding: 5px 30px;
  background-image: linear-gradient(to bottom, #f05e13, #fff);
  border: none;
  border-radius: 10px;
  font-weight: 900;
  font-size: 24px;
  color: black;
}

.savebutton {
  color: white;
  background: #f05e13;
  margin-top: 20px;
  height: fit-content;
  padding: 5px 60px;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 800;
}

.savebutton:hover,
.backbutton:hover {
  cursor: pointer;
  transform: scale(1.04);
}

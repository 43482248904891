.maincontainer {
  height: fit-content;
  width: fit-content;
  border-radius: 10px;
  padding: 10px;
  background: #314972;
  color: white;
  font-size: 22px;
  font-weight: 900;
  position: absolute;
  right: 2vw;
  top: 10vh;
}

.maincontainer:hover {
  cursor: pointer;
}

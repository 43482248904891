.maincontainer {
  padding: 50px;
  overflow-y: auto;
  background: #f5f5f5;
  height: 100vh;
  width: 100vw;
}

.topbar {
  display: flex;
  justify-content: flex-start;
}

.headingholder {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 30px 0px;
}

.centerformholder {
  height: fit-content;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  padding: 0vh 25vw;
}

.inputblock {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
}

.formlabel {
  font-size: 18px;
  color: #314a72;
  font-weight: 600;
}

.inputtext {
  width: 100%;
  height: 60px;
  padding: 30px;
  background-image: linear-gradient(to bottom, #ffedbf, #fff);
  border: none;
  border-radius: 10px;
  font-weight: 900;
  font-size: 24px;
}

.selectblock {
  width: 100%;
  height: 60px;
  padding: 5px 30px;
  background-image: linear-gradient(to bottom, #ffedbf, #fff);
  border: none;
  border-radius: 10px;
  font-weight: 900;
  font-size: 24px;
  color: black;
}

.dateinputblock {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.leftinput,
.rightinput {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.inputdate {
  height: 60px;
  padding: 30px;
  background-image: linear-gradient(to bottom, #ffedbf, #fff);
  border: none;
  border-radius: 10px;
  font-weight: 900;
  font-size: 24px;
}

.savebuttonholder {
  padding: 0px 0px;
}

.savebutton {
  color: white;
  background: #dfa510;
  height: fit-content;
  padding: 5px 60px;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 800;
}

.savebutton:hover,
.backbutton:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.errormessage {
  color: #ff0000;
  font-size: 12px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .maincontainer {
    padding: 5vw;
  }

  .centerformholder {
    width: 100%;
    padding: 0;
  }

  .inputblock {
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

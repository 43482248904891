.maincontainer {
  width: 80vw;
  height: 80vh;
  background: #fff;
  border-radius: 10px;
  padding: 2vh;
  overflow-y: scroll;
  z-index: 999 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.topbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

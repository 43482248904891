.maincontainer {
  padding: 50px;
  overflow-y: auto;
  background: #f5f5f5;
  height: 100vh;
  width: 100vw;
}

.backbutton:hover,
.individualcard:hover,
.doublearrow:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.topbar {
  display: flex;
  justify-content: flex-start;
}

.headingholder {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 30px 0px;
}

.libraryicon {
  width: 40px;
  height: 40px;
}

.heading {
  color: #dfa510;
  font-weight: 900;
}

.navigationcardholders {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
}

.individualcard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60vw;
  height: 100px;
  padding: 30px 60px;
  background-image: linear-gradient(to bottom, #ffedbf, #fff);
  border-radius: 10px;
}

.individualcardgreen {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60vw;
  height: 100px;
  padding: 30px 60px;
  background-image: linear-gradient(to bottom, #ecfde9, #baffaf);
  border-radius: 10px;
}

.individualcardpink {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60vw;
  height: 100px;
  padding: 30px 60px;
  background-image: linear-gradient(to bottom, #ffeaea, #ffbcbc);
  border-radius: 10px;
}

.leftcard {
  flex: 1.2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.issuedonholder {
  flex: 1;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .maincontainer {
    padding: 5vw;
  }

  .individualcard,
  .individualcardgreen,
  .individualcardpink {
    width: 100%;
    padding: 2vw 2vw;
    height: fit-content;
  }

  .leftcard {
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

.maincontainer {
  padding: 50px;
  overflow-y: auto;
  background: #f5f5f5;
  height: 100vh;
  width: 100vw;
}

.topbar {
  display: flex;
  justify-content: flex-start;
}

.heading {
  color: #29ac14;
  font-weight: 900;
  font-size: 18px;
  text-align: center;
}
.addstudentcard {
  width: 24vw;
  height: 24vh;
  gap: 22px;
  border-radius: 10px;
  background-image: "../../assets/icons/libraryicon.png";
  background-repeat: repeat;
  background-color: #ffefe7;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.headingholder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0px;
}

.lefthead {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.noticeboardicon {
  width: 40px;
  height: auto;
}

.addbutton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: none;
  border-radius: 10px;
  background: #29ac14;
  color: white;
  padding: 5px 20px;
  font-size: 16px;
  font-weight: 8 00;
}

.middleholder {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 50px;
}

.leftside {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  overflow-y: auto;
  gap: 20px;
}

.topicnameholder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border: 1px solid #29ac14;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
}

.rightside {
  flex: 1.5;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
  background-image: linear-gradient(to bottom, #29ac14a6 65%, #fff);
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
}

.inputtext {
  width: 100%;
  height: 60px;
  padding-left: 20px;
  background-image: linear-gradient(to bottom, #f05e1363, #fff);
  border: 1px solid #f05e13;
  border-radius: 10px;
  font-weight: 900;
  font-size: 24px;
  margin: 0px;
}
.inputfeesitem {
  width: 45%;
  height: 60px;
  padding-left: 20px;
  background-image: linear-gradient(to bottom, #f05e1363, #fff);
  border: 1px solid #f05e13;
  border-radius: 10px;
  font-weight: 900;
  font-size: 24px;
  margin: 0px;
}
.feesitemblock {
  width: 100%;
  display: flex;
  gap: 5px;
  justify-content: space-between;
}
.topichead {
  font-size: 30px;
  color: #314a72;
  font-weight: 900;
}
.savebutton {
  color: white;
  background: #f05e13;
  height: fit-content;
  padding: 5px 60px;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 800;
  margin-top: 20px;
}
.deletebutton {
  color: white;
  background: #D32F2F;
  height: fit-content;
  padding: 5px 60px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 800;
  margin-top: 20px;
}

.savebutton:hover,
.backbutton:hover {
  cursor: pointer;
  transform: scale(1.04);
}
.addbutton {
  color: white;
  background: #f05e13;
  height: fit-content;
  padding: 5px 20px;
  border: none;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
}

.addbutton:hover,
.backbutton:hover {
  cursor: pointer;
  transform: scale(1.04);
}
.noticedescription {
  font-size: 20px;
  color: #314a72;
  font-weight: 600;
  padding: 0px 20px;
}

.backbutton:hover,
.addbutton:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.topicnameholder:hover {
  cursor: pointer;
}

.topicimage {
  width: 30%;
  height: auto;
}

.TemplateCardsDiv{
  display: flex;
  justify-content: center;
  align-items: center; 
  gap: 2vw;
  flex-wrap: wrap;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .maincontainer {
    padding: 5vw;
  }
  .addstudentcard{
    width: 90vw;
  }
  .feesitemblock {
    flex-direction: column;
  }
  .inputfeesitem {
    width: 100%;
  }
  .TemplateCardsDiv{
    flex-direction: column;
  }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

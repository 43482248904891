.maincontainer {
  padding: 50px;
  overflow-y: auto;
  background: #f5f5f5;
  height: 100vh;
  width: 100vw;
}

.topbar {
  display: flex;
  justify-content: flex-start;
}

.headingholder {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 30px 0px;
}

.cardholder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardgreen {
  width: 50vw;
  padding: 30px 60px;
  background-image: linear-gradient(to bottom, #ecfde9, #baffaf);
  border-radius: 10px;
}

.cardpink {
  width: 50vw;
  padding: 30px 60px;
  background-image: linear-gradient(to bottom, #ffeaea, #ffbcbc);
  border-radius: 10px;
}

.kitname {
  text-align: left;
  font-size: 22px;
  font-weight: 800;
}

.valueholder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.leftlabelholder {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

.rightlabel {
  display: flex;
  justify-content: flex-start;
  flex: 1;
}

.leftlabel,
.rightlabel {
  font-size: 18px;
  font-weight: 600;
}

.backbutton:hover {
  cursor: pointer;
  transform: scale(1.04);
}

.maincontainer {
  width: 40vw;
  background: white;
  padding: 3vw;
  border-radius: 10px;
  z-index: 999 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.heading {
  text-align: center;
  font-size: 1.8vw;
}

.buttonsholder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3vw;
  padding: 3vh 0;
}

.cancelbutton {
  color: white;
  background: #f05e13;
  height: fit-content;
  padding: 1vh 5vw;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 800;
}

.deletebutton {
  color: white;
  background: red;
  height: fit-content;
  padding: 1vh 5vw;
  border: none;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 800;
}

.cancelbutton:hover,
.deletebutton:hover {
  cursor: pointer;
}
